<template>
  <SmoothReflow>
    <div class="asset-picker-mobile-component">
      <div class="top-nav" v-if="showTopUI">
        <div
          class="back"
          @click="back"
          v-show="showInfoMenu || screen || isSharedVehicle"
        >
          <svg width="20" height="20" fill="currentColor" viewBox="0 0 16 16">
            <path
              fill-rule="evenodd"
              d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
            />
          </svg>
          BACK
        </div>
        <!-- <div class="close" @click="close" v-show="showInfoMenu || screen || isSharedVehicle"></div> -->
        <Tooltip
          @click.native="showTooltip"
          v-show="screen && tooltipScreenName"
        />
      </div>
      <div class="search-box" v-if="showSearchBox">
        <svg width="21.122" height="17.856" viewBox="0 0 21.122 17.856">
          <defs>
            <clipPath id="clip-path">
              <rect
                width="21.122"
                height="17.856"
                fill="none"
                stroke="#707070"
                stroke-width="1"
              />
            </clipPath>
          </defs>
          <g transform="translate(0 0)" clip-path="url(#clip-path)">
            <path
              d="M12.12,6.281a1.9,1.9,0,1,1,3.8,0M15.846.375H5.048A2.038,2.038,0,0,0,3.063,1.957L1.992,6.6H18.9L17.832,1.957A2.038,2.038,0,0,0,15.846.375Zm4.648,11.682v3.824a1.346,1.346,0,0,1-1.343,1.347h-.777a1.345,1.345,0,0,1-1.344-1.347V13.942m-13.193,0v1.939A1.346,1.346,0,0,1,2.5,17.228h-.78A1.346,1.346,0,0,1,.375,15.881V12.057m18.461-1.571A1.458,1.458,0,1,1,17.38,9.024,1.459,1.459,0,0,1,18.836,10.486Zm-13.9,0A1.457,1.457,0,1,1,3.479,9.024,1.459,1.459,0,0,1,4.936,10.486Zm13.3-3.89H2.635A2.271,2.271,0,0,0,.375,8.862v2.813a2.271,2.271,0,0,0,2.26,2.267h15.6a2.27,2.27,0,0,0,2.26-2.267V8.862A2.27,2.27,0,0,0,18.235,6.6Z"
              transform="translate(0.126 0.127)"
              fill="none"
              stroke="#536e84"
              stroke-width="1"
            />
            <line
              x2="7.258"
              transform="translate(6.932 10.382)"
              fill="none"
              stroke="#536e84"
              stroke-linecap="round"
              stroke-width="1"
            />
          </g>
        </svg>
        <input type="search" v-model="search" />
      </div>
      <ul class="assets" v-show="!isOnFeatureScreen">
        <li
          v-for="asset in filteredAssets"
          :key="asset.SafeAndSoundVehicleId"
          :class="{
            active: asset.SafeAndSoundVehicleId == selectedAssetSafeAndSoundVehicleId,
            basicIoT: isBasicIoT,
          }"
          v-show="showAsset(asset.SafeAndSoundVehicleId)"
          @click="activate(asset)"
        >
          <div class="share-vehicle-icon icon" v-if="asset.shared">
            <img
              src="~@/assets/images/share-vehicle.svg"
              width="21"
              height="24"
              alt
            />
          </div>
          <div
              class="asset-icon"
              :class="{ isInRecovery: asset.IsInRecovery }"
              v-else
          >
            <img
              :src="getVehicleImageUrl(asset)"
              class="icon"
              width="55"
              height="55"
              alt
            />
            <div
              class="health-warning"
              v-if="asset.RegistrationNumber && !asset.IsHealthy"
            ></div>
          </div>
          <div class="content">
            <template v-if="isBasicIoT">
              <div class="line1">
                {{ asset.Nickname ? asset.Nickname : asset.RegistrationNumber }}
              </div>
              <div>NETSTAR <strong>BASIC-IoT</strong></div>
            </template>
            <template v-else>
              <div class="container">
                <div class="row">
                  <div class="col-12 p-0">
                    <div class="name">
                      {{
                        asset.Nickname
                          ? asset.Nickname
                          : asset.RegistrationNumber
                      }}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="recovery" v-if="asset.IsInRecovery">
                    <strong>VEHICLE RECOVERY IN PROGRESS</strong>
                    <p class="m-0">
                      For your safety, tracking is temporarily unavailable. Please
                      contact the Emergency Call Centre on<br />
                      <a href="tel:0800112222">0800 11 22 22</a> for further
                      information.
                    </p>
                  </div>
                  <div class="telemetry" v-else>
                    <div class="col-6 p-0">
                      <div class="address">{{ asset.GeoAddress }}</div>
                      <strong v-if="latitude && longitude">{{
                          latitude + ", " + longitude
                        }}</strong>
                    </div>
                    <div class="col-6 p-0">
                      <div class="speed" :class="{ danger: isSpeeding(asset) }">
                        {{ speedValue(asset) }}
                      </div>
                      <!-- <div class="road-speed">
                          Road Speed:
                          <span class="font-weight-bold"
                            >{{ asset.SpeedLimit }} kph</span
                          >
                        </div> -->
                    </div>
                    <!-- <div class="road-speed">
                    Road Speed:
                    <span class="font-weight-bold"
                      >{{ asset.SpeedLimit }} kph</span
                    >
                  </div> -->
                  </div>
                </div>
                <div class="row align-items-center">
                  <div class="product-name">
                    <strong>{{ asset.ProductDisplayName }}</strong>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </li>
      </ul>
      <div
        v-show="showInfoMenu"
        class="info-menu"
        :class="{ basicIoT: isBasicIoT }"
      >
        <template v-if="isBasicIoT">
          <div class="box">
            <div class="update-blurb">
              <img
                src="~@/assets/images/hourglass.gif"
                width="42"
                height="42"
                alt
              />
              <div>
                <div>Last update at</div>
                <div>
                  <strong class="green">{{ time }}</strong> {{ date }}
                </div>
                <div>(Updates occur every 20 minutes while in motion)</div>
              </div>
            </div>
            <div class="container p-3">
              <div class="row">
                <div class="col-12 d-flex mb-2">
                  <img
                    src="~@/assets/images/pin2.svg"
                    class="mr-2"
                    width="16"
                    height="16"
                    alt
                  />
                  <div>
                    <div class="address">{{ address }}</div>
                    <strong v-if="latitude && longitude">{{
                      latitude + ", " + longitude
                    }}</strong>
                  </div>
                </div>
                <div class="col-12 d-flex align-items-center">
                  <img
                    src="~@/assets/images/battery.svg"
                    class="mr-2"
                    width="16"
                    height="13"
                    alt
                  />
                  <div>
                    Unit Battery:
                    <strong :class="batteryPercentageClassName"
                      >{{ batteryPercentage }}%</strong
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <div class="box" v-else-if="hasTelematics">
          <div class="container p-3">
            <div class="row">
              <div class="col-12 d-flex align-items-center mb-2">
                <img
                  src="~@/assets/images/calendar.svg"
                  class="mr-2"
                  width="16"
                  height="16"
                  alt
                />
                <div>
                  <span class="font-weight-bold">{{ date }}</span> {{ time }}
                </div>
              </div>
              <div class="col-12 d-flex align-items-center mb-2">
                <img
                  src="~@/assets/images/battery.svg"
                  class="mr-2"
                  width="16"
                  height="13"
                  alt
                />
                <div>
                  Battery Voltage: <strong>{{ batteryVoltage }}V</strong>
                </div>
              </div>
              <div class="col-12 d-flex align-items-center">
                <img
                  src="~@/assets/images/gauge.svg"
                  class="mr-2"
                  width="16"
                  height="16"
                  alt
                />
                <div>
                  Odometer Reading: <strong>{{ odometer }}km</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="box">
          <div class="container">
            <div class="row">
              <div class="col">
                <div
                  class="menu-item"
                  :class="{ disabled: !hasTrips || isInRecovery }"
                  @click="
                    hasTrips && !isInRecovery
                    ? showScreen('trips')
                    : handleInactiveFeature()
                  "
                >
                  <div class="screen-icon">
                    <img
                      src="~@/assets/images/trips.svg"
                      width="22"
                      height="22"
                      alt
                    />
                  </div>
                  TRIPS
                </div>
              </div>
              <div class="col">
                <div
                  class="menu-item"
                  :class="{ disabled: !hasAlerts || isInRecovery }"
                  @click="
                    hasAlerts && !isInRecovery
                    ? showScreen('alerts')
                    : handleInactiveFeature()
                  "
                >
                  <div class="screen-icon">
                    <img
                      src="~@/assets/images/alerts.svg"
                      width="22"
                      height="22"
                      alt
                    />
                  </div>
                  ALERTS
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="menu-item"
                  :class="{ disabled: !hasLogbooks || isInRecovery }"
                  @click="
                    hasLogbooks && !isInRecovery
                      ? showScreen('logbooks')
                      : handleInactiveFeature()
                  "
                >
                  <div class="screen-icon">
                    <img
                      src="~@/assets/images/logbooks.svg"
                      width="21"
                      height="18"
                      alt
                    />
                  </div>
                  LOGBOOKS
                </div>
              </div>
              <div class="col">
                <div
                  class="menu-item"
                  :class="{ disabled: !hasMap || isInRecovery }"
                  @click="
                    hasMap && !isInRecovery
                    ? showScreen('geo-zones')
                    : handleInactiveFeature()
                  "
                >
                  <div class="screen-icon">
                    <img
                      src="~@/assets/images/geo-zones.svg"
                      width="20"
                      height="20"
                      alt
                    />
                  </div>
                  GEO ZONES
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="menu-item"
                  :class="{ disabled: !hasBehaviour || isInRecovery }"
                  @click="
                    hasBehaviour && !isInRecovery
                      ? showScreen('behaviour')
                      : handleInactiveFeature()
                  "
                >
                  <div class="screen-icon">
                    <img
                      src="~@/assets/images/behaviour.svg"
                      width="22"
                      height="20"
                      alt
                    />
                  </div>
                  BEHAVIOUR
                </div>
              </div>
              <div class="col">
                <div class="menu-item" @click="showScreen('certificates')">
                  <div class="screen-icon">
                    <img
                      src="~@/assets/images/certificate.svg"
                      width="16"
                      height="26"
                      alt
                    />
                  </div>
                  CERTIFICATES
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="menu-item" @click="showScreen('manage-vehicle')">
                  <div class="screen-icon">
                    <img
                      src="~@/assets/images/manage-vehicle.svg"
                      width="21"
                      height="21"
                      alt
                    />
                  </div>
                  MANAGE VEHICLE
                </div>
              </div>
              <div class="col">
                <div class="menu-item" @click="showScreen('reminders')">
                  <div class="screen-icon">
                    <img
                      src="~@/assets/images/reminders.svg"
                      width="19"
                      height="20"
                      alt
                    />
                  </div>
                  REMINDERS
                </div>
              </div>
            </div>
            <div class="row">
              <!-- <div class="col">
                <div
                  class="menu-item"
                  :class="{ disabled: !hasShareVehicle }"
                  @click="hasShareVehicle ? showScreen('share-vehicles') : handleInactiveFeature()"
                >
                  <div class="screen-icon">
                    <img src="~@/assets/images/share-vehicle.svg" width="19" height="22" alt />
                  </div>
                  SHARE VEHICLE
                </div>
              </div> -->
              <!-- <div class="col" v-if="isEarlyWarning">
                <div
                  class="menu-item"
                  :class="{
                    armed: isArmed && !isInRecovery,
                    disabled: isInRecovery,
                  }"
                  @click="
                    isInRecovery
                      ? handleInactiveFeature()
                      : showScreen('early-warning')
                  "
                >
                  <div class="screen-icon">
                    <img
                      :src="earlyWarningIconUrl"
                      width="21"
                      height="20"
                      alt
                    />
                  </div>
                  ARM OR DISARM
                </div>
              </div> -->
              <div class="col">
                <div
                  class="menu-item"
                  :class="{
                    warning: !isHealthy && !isInRecovery,
                    disabled: isInRecovery,
                  }"
                  @click="
                    isInRecovery
                      ? handleInactiveFeature()
                      : showScreen('health-status')
                  "
                >
                  <div class="screen-icon">
                    <img
                      :src="healthStatusIconUrl"
                      width="21"
                      height="21"
                      alt
                    />
                  </div>
                  HEALTH STATUS
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="arm-disarm-panel" v-if="hasArmDisarm">
        <div class="control">
          <img src="@/assets/images/early-warning-disarm.svg" class="alert-icon" width="14" height="24" alt />
          <span class="ml-3 mr-2">Disarm</span>
          <div class="custom-control custom-switch">
            <input type="checkbox" class="custom-control-input" id="toggleArmedStatus" v-model="selectedAsset.IsArmed" @click.prevent="toggleArmedStatus" />
            <label class="custom-control-label" for="toggleArmedStatus" />
          </div>
          <span class="ml-2 mr-3">Arm</span>
          <img src="@/assets/images/early-warning-arm.svg" class="alert-icon" width="14" height="24" alt />
        </div>
        <div class="status">
          <span>Status: </span
          ><span class="font-weight-bold" :class="{ armed: selectedAsset.IsArmed, disarmed: !selectedAsset.IsArmed }">{{
            selectedAsset.IsArmed ? "Armed" : "Disarmed"
          }}</span>
        </div>
      </div>
      <div class="py-1 mt-3" v-if="showInfoMenu && isUpgradeable">
        <div class="upgrade-status" v-if="selectedAsset.PendingUpgrade">
          <img
            src="~@/assets/images/vehicle-fitment.svg"
            width="24"
            height="24"
            alt
          />
          <div>Upgrade Status: <span>Awaiting Fitment</span></div>
        </div>
        <div class="text-right" v-else>
          <button class="btn btn-outline" @click="upgrade()">
            UPGRADE PRODUCT
          </button>
        </div>
      </div>
      <component
        v-show="screen"
        :is="screen"
        :key="key"
        @showTopUI="toggleTopUI"
        @showScreen="showScreen"
      />
    </div>
  </SmoothReflow>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.asset-picker-mobile-component {
  .search-box {
    display: flex;
    align-items: center;
    background: #fff;
    padding: 8px 15px;
    margin: 15px 15px 25px 15px;
    border-radius: 20px;
    box-shadow: $drop-shadow;

    input[type="search"] {
      flex: 1;
      font-weight: 600;
      color: $brand-color-1;
      margin-left: 10px;
      border: none;

      &:focus {
        outline: none;
        border-bottom: 1px solid $brand-color-1;
      }
    }
  }

  .top-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgba(255, 255, 255, 0.8);
    position: fixed;
    top: auto;
    left: 15px;
    z-index: 1;
    width: calc(100% - 30px);
    margin-top: -25px;

    .back {
      display: flex;
      align-items: center;
      background: transparent;
      font-weight: bold;
      color: $brand-color-2;
      line-height: 1;
      padding: 5px 5px 5px 0;
      margin-left: -5px;

      svg {
        color: $brand-color-1;
      }

      &:focus {
        outline: none;
      }
    }
  }

  .close {
    background: transparent;
    position: fixed;
    right: 5px;
    top: auto;
    z-index: 2;
    width: 36px;
    height: 36px;
    opacity: 1;
    margin-top: -34px;

    &:before,
    &:after {
      background: $brand-color-2;
      position: absolute;
      top: 12px;
      left: 17px;
      content: " ";
      height: 16px;
      width: 2px;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }

    &:focus {
      outline: none;
    }
  }

  .assets {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      display: flex;
      align-items: center;
      padding: 0 15px;
      margin-bottom: 10px;

      .icon {
        border-radius: 50%;
        box-shadow: $drop-shadow;
      }

      .share-vehicle-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
      }

      .asset-icon {
        position: relative;
        margin-right: 15px;
        border-radius: 50%;

        &.isInRecovery {
          border: 3px solid #c3281c;

          &:after {
            content: "";
            background: rgba(#c3281c, 0.5);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }

        .health-warning {
          background: $warning;
          position: absolute;
          top: 0;
          right: 20px;
          width: 10px;
          height: 10px;
          border-radius: 50%;
        }
      }

      .content {
        flex-grow: 1;
        max-width: calc(100% - 65px);
        padding: 5px 0;
        border-bottom: 1px solid $brand-color-2;

        .name {
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: bold;
          font-style: normal;
          color: $brand-color-2;
          text-transform: uppercase;
        }

        .recovery {
          font-size: 0.75rem;
          font-weight: 600;
          line-height: 1.3;
          color: #c3281c;
          text-align: left;
          margin-top: 5px;

          strong {
            margin-top: 15px;
          }

          a {
            font-weight: bolder;
            color: #c3281c;
          }
        }

        .telemetry {
          display: flex;

          .address {
            color: $brand-color-1;
          }

          .speed {
            color: $brand-color-1;
            text-align: right;

            &.danger {
              color: $red;
            }
          }

          .road-speed {
            font-size: 0.6875rem;
            color: $brand-color-2;
            text-align: right;
          }
        }

        .product-name {
          font-size: 0.75rem;
          color: $brand-color-2;
          text-transform: uppercase;
          margin-top: 5px;
        }
      }

      &.active {
        .content {
          border: none;
        }
      }

      &.basicIoT {
        padding-top: 15px;
        padding-bottom: 15px;
        color: $brand-color-2;

        .asset-icon {
          margin-right: 5px;
        }

        .line1 {
          font-size: 1.125rem;
          font-style: normal;
          font-weight: bold;
          color: $brand-color-2;
        }
      }
    }
  }

  .info-menu {
    .box {
      background: $brand-color-4;
      padding: 15px;

      &:first-of-type {
        margin-bottom: 15px;
      }

      .container {
        background: #fff;
        padding: 5px 0;

        .row {
          margin-left: 0;
          margin-right: 0;

          .menu-item {
            display: flex;
            align-items: center;
            font-weight: bold;
            font-style: normal;
            font-size: 0.8125rem;
            color: $brand-color-2;
            padding: 5px 0;

            &.armed {
              color: $red;
            }

            &.warning {
              color: $warning;
            }

            .screen-icon {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 22px;
              height: 25px;
              margin-right: 5px;

              img {
                vertical-align: bottom;
              }
            }

            &:hover {
              cursor: pointer;
            }

            &.disabled {
              opacity: 0.5;
              filter: grayscale(1);

              &:hover {
                cursor: inherit;
              }
            }
          }
        }
      }
    }

    &.basicIoT {
      font-size: 0.8125rem;
      color: #4c5258;

      .box {
        font-size: 0.75rem;

        .update-blurb {
          display: flex;
          align-items: flex-start;
          margin-bottom: 15px;

          > img {
            margin-top: -2px;
            margin-right: 2px;
          }

          .green {
            color: #65bc36;
          }
        }

        .good {
          color: #65bc36;
        }

        .warning {
          color: #ff7f00;
        }
      }
    }
  }

  .arm-disarm-panel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $brand-color-4;
    color: $brand-color-1;
    padding: 15px;
    margin-top: 15px;

    .control {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      font-size: 0.9375rem;
      font-weight: 600;
    }

    .status {
      width: 120px;
      font-size: 0.75rem;
      text-align: center;

      .armed {
        color: #338905;
      }

      .disarmed {
        color: #d50002;
      }
    }
  }

  .upgrade-status {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8125rem;
    font-weight: bold;
    color: $brand-color-1;

    img {
      margin-right: 10px;
    }

    span {
      font-style: normal;
      font-weight: 400;
    }
  }

  .screen {
    padding: 15px 0 0 0;
  }
}
</style>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { assetService, modalService, tooltipService } from "@/services";
import Tooltip from "@/components/Tooltip";
import AssetIcon from "@/components/AssetIcon";
import Alerts from "@/components/screens/Alerts";
import Behaviour from "@/components/screens/Behaviour";
import Certificates from "@/components/screens/Certificates";
import EarlyWarning from "@/components/screens/EarlyWarning";
import GeoZones from "@/components/screens/GeoZones";
import GeoZoneEditor from "@/components/screens/GeoZoneEditor";
import HealthStatus from "@/components/screens/HealthStatus";
import ManageVehicle from "@/components/screens/ManageVehicle";
import Logbooks from "@/components/screens/Logbooks";
import Reminders from "@/components/screens/Reminders";
import ShareVehicles from "@/components/screens/ShareVehicles";
import Trips from "@/components/screens/Trips";

export default {
  name: "AssetPickerMobile",
  components: {
    AssetIcon,
    Tooltip,
  },
  data() {
    return {
      selectedAsset: null,
      selectedAssetRegistrationNumber: "",
      selectedAssetSafeAndSoundVehicleId: null,
      tooltipScreenName: "",
      search: "",
      screen: false,
      showInfoMenu: false,
      showTopUI: true,
      isOnFeatureScreen: false,
      isSharedVehicle: false,
    };
  },
  computed: {
    ...mapState("asset", ["activeSafeAndSoundVehicleId"]),
    ...mapState("map", ["showGeoZoneEditor"]),
    ...mapState("navigation", ["parentScreenKey"]),
    ...mapGetters("asset", ["getAsset", "getAllAssets"]),
    key() {
      return this.parentScreenKey;
    },
    filteredAssets() {
      return this.getAllAssets().filter(
        (asset) =>
          asset.RegistrationNumber &&
          !asset.shared &&
          (asset.RegistrationNumber.toLowerCase().includes(
            this.search.toLowerCase()
          ) ||
            asset.Nickname.toLowerCase().includes(this.search.toLowerCase()))
      );
    },
    assetsEligibleForDisplay() {
      return this.getAllAssets().filter(
        (asset) => asset.RegistrationNumber && !asset.shared
      );
    },
    showSearchBox() {
      return !this.selectedAsset && this.assetsEligibleForDisplay.length > 5;
    },
    date() {
      if (this.selectedAsset) {
        return this.selectedAsset.DateTime.split("T")[0];
      }
      return "";
    },
    time() {
      if (this.selectedAsset) {
        return this.selectedAsset.DateTime.split("T")[1];
      }
      return "";
    },
    address() {
      if (this.selectedAsset) {
        return this.selectedAsset.GeoAddress;
      }
      return "";
    },
    latitude() {
      if (this.selectedAsset) {
        return this.selectedAsset.Latitude;
      }
      return "";
    },
    longitude() {
      if (this.selectedAsset) {
        return this.selectedAsset.Longitude;
      }
      return "";
    },
    batteryVoltage() {
      if (this.selectedAsset) {
        return this.selectedAsset.BatteryVoltage;
      }
      return "";
    },
    batteryPercentage() {
      if (this.selectedAsset) {
        return 100;
        // return this.selectedAsset.BatteryPercentage;
      }
      return "";
    },
    batteryPercentageClassName() {
      if (this.selectedAsset) {
        return "good";
        // return this.selectedAsset.BatteryPercentage > 40 ? "good" : "warning";
      }
      return "";
    },
    odometer() {
      if (this.selectedAsset) {
        return this.selectedAsset.Odometer;
      }
      return "";
    },
    isNano() {
      return this.selectedAsset && this.selectedAsset.ProductName === "Nano";
    },
    isBasic() {
      return this.selectedAsset && this.selectedAsset.ProductName === "Basic";
    },
    isBasicIoT() {
      return (
        this.selectedAsset &&
        this.selectedAsset.ProductDisplayName === "Netstar Basic IoT"
      );
    },
    isUpgradeable() {
      return this.isNano || this.isBasic;
    },
    isHealthy() {
      return this.selectedAsset && this.selectedAsset.IsHealthy;
    },
    isInRecovery() {
      return this.selectedAsset && this.selectedAsset.IsInRecovery;
    },
    isEarlyWarning() {
      return (
        this.selectedAsset && this.selectedAsset.ProductName === "Early Warning"
      );
    },
    isArmed() {
      return this.isEarlyWarning && this.selectedAsset.IsArmed;
    },
    earlyWarningIconUrl() {
      return this.isArmed
        ? require("@/assets/images/early-warning-armed.svg")
        : require("@/assets/images/early-warning-disarmed.svg");
    },
    healthStatusIconUrl() {
      return this.isHealthy
        ? require("@/assets/images/health-status-good.svg")
        : require("@/assets/images/health-status-bad.svg");
    },
    hasTelematics() {
      return (
        this.selectedAsset &&
        this.selectedAsset.DateTime &&
        this.selectedAsset.Latitude &&
        this.selectedAsset.Longitude
      );
    },
    hasAlerts() {
      return (
        this.selectedAsset && !this.isBasicIoT && assetService.hasAlertsFeature(this.selectedAsset.ProductName)
      );
    },
    hasArmDisarm() {
      return this.selectedAsset && assetService.hasArmDisarmFeature(this.selectedAsset.ProductName);
    },
    hasBehaviour() {
      return (
        this.selectedAsset &&
        !this.isBasicIoT &&
        assetService.hasBehaviourFeature(this.selectedAsset.ProductName)
      );
    },
    hasLogbooks() {
      return (
        this.selectedAsset &&
        !this.isBasicIoT &&
        assetService.hasTripsFeature(this.selectedAsset.ProductName)
      );
    },
    hasMap() {
      return (
        this.selectedAsset &&
        assetService.hasMapFeature(this.selectedAsset.ProductName)
      );
    },
    hasShareVehicle() {
      return false;
      // return (
      //   this.selectedAsset &&
      //   assetService.hasShareVehicleFeature(this.selectedAsset.ProductName)
      // );
    },
    hasTrips() {
      return (
        this.selectedAsset &&
        !this.isBasicIoT &&
        assetService.hasTripsFeature(this.selectedAsset.ProductName)
      );
    },
  },
  methods: {
    ...mapActions("map", ["setMapBounds"]),
    ...mapActions("asset", ["setActiveAsset"]),
    ...mapMutations("asset", [
      "setMapActive",
      "updateActiveSafeAndSoundVehicleId",
    ]),
    ...mapMutations("navigation", [
      "updateActiveScreenName",
      "updateShowSheet",
    ]),
    ...mapMutations("asset", ["updateVehicleArmedStatus"]),
    getVehicleImageUrl(asset) {
      if (asset.ImageUrl) {
        return asset.ImageUrl;
      }
      return require("@/assets/images/avatar.svg");
    },
    activate(asset) {
      this.selectedAsset = asset;
      this.selectedAssetSafeAndSoundVehicleId = asset.SafeAndSoundVehicleId;
      this.setActiveAsset(asset.SafeAndSoundVehicleId);
      this.isSharedVehicle = asset.shared;
      if (!asset.shared && !this.isOnFeatureScreen) {
        this.showInfoMenu = true;
      }
      if (!asset.disabled) {
        this.setMapActive(asset.SafeAndSoundVehicleId);
        this.setMapBounds();
      }
    },
    back() {
      this.$emit("screenName", "");
      if (this.showInfoMenu) {
        this.selectedAsset = null;
        this.selectedAssetSafeAndSoundVehicleId = null;
        this.updateActiveSafeAndSoundVehicleId(null);
        this.showInfoMenu = false;
        this.resetMap();
      } else {
        if (this.isSharedVehicle) {
          this.selectedAsset = null;
          this.selectedAssetSafeAndSoundVehicleId = null;
          this.updateActiveSafeAndSoundVehicleId(null);
          this.isSharedVehicle = false;
          this.resetMap();
        } else {
          this.showInfoMenu = true;
          this.isOnFeatureScreen = false;
          this.screen = false;
          this.updateActiveScreenName(null);
        }
      }
    },
    // close() {
    //   this.selectedAsset = null;
    //   this.selectedAssetSafeAndSoundVehicleId = null;
    //   this.updateActiveSafeAndSoundVehicleId(null);
    //   this.isSharedVehicle = false;
    //   this.showInfoMenu = false;
    //   this.isOnFeatureScreen = false;
    //   this.screen = false;
    //   this.updateActiveScreenName(null);
    //   this.$emit("screenName", "");
    //   this.resetMap();
    //   this.updateShowSheet(false);
    // },
    resetMap() {
      this.setMapActive();
      this.setMapBounds();
    },
    showAsset(vehicleId) {
      return (
        !this.selectedAssetSafeAndSoundVehicleId ||
        this.selectedAssetSafeAndSoundVehicleId === vehicleId
      );
    },
    toggleTopUI(flag) {
      this.showTopUI = flag;
    },
    showScreen(screenName) {
      this.tooltipScreenName = "";
      let component = null;
      switch (screenName) {
        case "alerts":
          component = Alerts;
          this.tooltipScreenName = screenName;
          break;
        case "behaviour":
          component = Behaviour;
          this.tooltipScreenName = screenName;
          break;
        case "certificates":
          component = Certificates;
          break;
        case "early-warning":
          component = EarlyWarning;
          this.tooltipScreenName = screenName;
          break;
        case "geo-zones":
          component = GeoZones;
          this.tooltipScreenName = screenName;
          break;
        case "geo-zone-editor":
          component = GeoZoneEditor;
          break;
        case "health-status":
          component = HealthStatus;
          break;
        case "logbooks":
          component = Logbooks;
          this.tooltipScreenName = screenName;
          break;
        case "manage-vehicle":
          component = ManageVehicle;
          this.tooltipScreenName = screenName;
          break;
        case "reminders":
          component = Reminders;
          this.tooltipScreenName = screenName;
          break;
        case "share-vehicles":
          component = ShareVehicles;
          break;
        case "trips":
          component = Trips;
          this.tooltipScreenName = screenName;
          break;
      }
      if (component) {
        this.updateActiveScreenName(screenName);
        this.screen = component;
        this.showInfoMenu = false;
        this.isOnFeatureScreen = true;
        this.$emit("screenName", screenName);
      }
    },
    showTooltip() {
      tooltipService.showTooltip(this.tooltipScreenName);
    },
    handleInactiveFeature() {
      if (this.isInRecovery) {
        modalService.showRecoveryInProgressDialog(this);
      } else if (this.isBasicIoT) {
        modalService.showNotSupportedNoUpgradeDialog(this);
      } else {
        modalService.showNotSupportedDialog(this);
      }
    },
    isSpeeding(asset) {
      return Math.abs(asset.Speed) > Math.abs(asset.SpeedLimit);
    },
    speedValue(asset) {
      return asset.Speed > 0 ? asset.Speed + " kph" : asset.EventDescription;
    },
    upgrade() {
      try {
        this.$gtag.event("upgrade_product");
      } catch (err) {}
      this.updateShowSheet(false);
      modalService.showModal(this, "product-upgrade-options");
    },
    toggleArmedStatus() {
      modalService.showArmDisarmConfirmationDialog(this.selectedAsset.IsArmed ? "disarm" : "arm", () => {
        const vehicleId = this.selectedAsset.SafeAndSoundVehicleId;
        const updatedArmedValue = !this.selectedAsset.IsArmed;

        assetService
            .updateVehicleArmedStatus(vehicleId, updatedArmedValue)
            .then((result) => {
              if (result) {
                modalService.showArmDisarmRequestReceivedDialog(() => {
                  setTimeout(() => {
                    this.updateVehicleArmedStatus({
                      vehicleId: vehicleId,
                      status: updatedArmedValue,
                    });
                  }, 5000);
                });
              } else {
                modalService.showErrorDialog();
              }
            })
            .catch(() => {
              modalService.showErrorDialog();
            });
      });
    },
  },
  watch: {
    activeSafeAndSoundVehicleId(value) {
      if (value) {
        const asset = this.getAsset(value);
        this.activate(asset);
        this.updateShowSheet(true);
      }
    },
    showGeoZoneEditor(value) {
      if (value) {
        this.showScreen("geo-zone-editor");
      }
    },
  },
};
</script>

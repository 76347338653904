<template>
  <div
    class="asset-icon-component"
    :class="{ noMapFeatureViewer: !hasMap && showInfo }"
  >
    <img
      src="~@/assets/images/early-warning-armed-badge.svg"
      width="26"
      height="26"
      alt
      class="armed-badge"
      v-if="isArmed && !isInRecovery"
    />
    <div
        class="health-warning"
        v-if="registrationNumber && !isHealthy && !isInRecovery"
    ></div>
    <div
      class="icon"
      :class="{
        default: !registrationNumber,
        marker: isMarker,
        featureViewer: isFeatureViewer,
        shared: isShared,
        active: active,
        assetPicker: isAssetPicker,
        lower: !active && isAssetPicker,
        sendToBack: sendToBack,
        isInRecovery: isInRecovery,
      }"
      @click="toggleInfo()"
      @mouseover="openInfo()"
      @mouseout="clearHover()"
    >
      <img
        v-if="hasUserImage"
        :src="asset.ImageUrl"
        :alt="registrationNumber"
      />
      <template v-else>
        <svg
          width="18"
          height="21"
          viewBox="0 0 25.86 29.7"
          class="share-vehicle-icon"
          v-if="registrationNumber && isShared"
        >
          <g>
            <g>
              <path
                style="fill: none; stroke-width: 1.5"
                d="M18.72,6.64H6.41c-1.08,0-2.02,0.84-2.26,2.03l-1.22,5.95H22.2l-1.22-5.95C20.74,7.48,19.8,6.64,18.72,6.64z"
              />
              <path
                style="fill: none; stroke-width: 1.5"
                d="M24.3,21.98v5.15c0,1-0.81,1.81-1.81,1.81h-1.05c-1,0-1.81-0.81-1.81-1.81v-2.61"
              />
              <path
                style="fill: none; stroke-width: 1.5"
                d="M5.42,24.52v2.61c0,1-0.81,1.81-1.81,1.81H2.56c-1,0-1.81-0.81-1.81-1.81v-5.15"
              />
              <path
                style="fill: none; stroke-width: 1.5"
                d="M21.71,14.62H3.4c-1.46,0-2.65,1.37-2.65,3.05v3.79c0,1.68,1.19,3.05,2.65,3.05h18.31
			c1.46,0,2.65-1.37,2.65-3.05v-3.79C24.36,15.99,23.17,14.62,21.71,14.62z"
              />
              <path
                style="fill: none; stroke-width: 1.5"
                d="M6.9,19.86c0,1.09-0.88,1.97-1.96,1.97c-1.09,0-1.96-0.88-1.96-1.97c0-1.09,0.88-1.97,1.96-1.97
			C6.02,17.89,6.9,18.77,6.9,19.86z"
              />
              <path
                style="fill: none; stroke-width: 1.5"
                d="M21.97,19.86c0,1.09-0.88,1.97-1.96,1.97c-1.09,0-1.96-0.88-1.96-1.97c0-1.09,0.88-1.97,1.96-1.97
			C21.09,17.89,21.97,18.77,21.97,19.86z"
              />
              <path
                style="fill: none; stroke-width: 1.5"
                d="M13.76,14.19c0-1.42,1.15-2.57,2.56-2.57c1.42,0,2.56,1.15,2.56,2.57"
              />
              <line
                style="fill: none; stroke-width: 1.5"
                x1="9.42"
                y1="19.55"
                x2="15.49"
                y2="19.55"
              />
            </g>
            <path
              style="fill: none; stroke-width: 1.5"
              d="M25.11,5.92v2.09c0,0.27-0.22,0.49-0.49,0.49h-4.2v4.2c0,0.27-0.22,0.49-0.49,0.49h-2.09
		c-0.27,0-0.48-0.22-0.48-0.49v-4.2h-4.21c-0.27,0-0.48-0.22-0.48-0.49V5.92c0-0.27,0.21-0.48,0.48-0.48h4.21V1.23
		c0-0.27,0.21-0.48,0.48-0.48h2.09c0.27,0,0.49,0.21,0.49,0.48v4.21h4.2C24.89,5.44,25.11,5.65,25.11,5.92z"
            />
          </g>
        </svg>
        <svg
          width="15"
          height="21"
          viewBox="0 0 18.8 26.31"
          class="pin-icon"
          v-if="registrationNumber && !isShared"
        >
          <g>
            <path
              style="fill: none; stroke-width: 2"
              d="M1,9.4c0,4.64,8.4,15.91,8.4,15.91s8.4-11.27,8.4-15.91S14.04,1,9.4,1S1,4.76,1,9.4z"
            />
            <circle
              style="fill: none; stroke-width: 2"
              cx="9.4"
              cy="9.26"
              r="3.18"
            />
          </g>
        </svg>
        <div class="text" :class="{ truncate: registrationNumber }">
          {{ registrationNumber ? registrationNumber : "All Vehicles" }}
        </div>
        <hr v-if="registrationNumber" />
      </template>
    </div>
    <transition name="slide-diagonal">
      <div v-if="(isMarker || isFeatureViewer) && showInfo" class="info">
        <div class="header">
          <div class="title">
            {{ asset.Nickname ? asset.Nickname : registrationNumber }}
          </div>
          <div class="separator">|</div>
          <div class="product-name">
            <strong>{{ asset.ProductDisplayName }}</strong>
          </div>
          <img
            src="~@/assets/images/notification.png"
            width="30"
            height="30"
            alt
            class="notification"
            v-if="asset.notification"
          />
          <button class="close" @click="toggleInfo()"></button>
        </div>
        <div class="content container">
          <div class="recovery" v-if="isInRecovery">
            <img
                src="~@/assets/images/vehicle-warning.svg"
                width="34"
                height="32"
                alt
            />
            <strong>VEHICLE RECOVERY IN PROGRESS</strong>
            <p class="m-0">
              For your safety, tracking is temporarily unavailable.<br />Please
              contact the Emergency Call Centre on<br />
              <a href="tel:0800112222">0800 11 22 22</a> for further information.
            </p>
          </div>
          <div class="basic-iot" v-else-if="isBasicIoT">
            <div class="box">
              <div class="update-blurb">
                <img
                  src="~@/assets/images/hourglass.gif"
                  width="42"
                  height="42"
                  alt
                />
                <div>
                  <div>
                    Last update at
                    <strong class="green">{{ time }}</strong> {{ date }}
                  </div>
                  <div>(Updates occur every 20 minutes while in motion)</div>
                </div>
              </div>
              <div class="container p-3">
                <div class="row">
                  <div class="col-12 d-flex mb-2">
                    <img
                      src="~@/assets/images/pin2.svg"
                      class="mr-2"
                      width="16"
                      height="16"
                      alt
                    />
                    <div>
                      <div class="address">{{ asset.GeoAddress }}</div>
                      <strong v-if="asset.Latitude && asset.Longitude">{{
                        asset.Latitude + ", " + asset.Longitude
                      }}</strong>
                    </div>
                  </div>
                  <div class="col-12 d-flex align-items-center">
                    <img
                      src="~@/assets/images/battery.svg"
                      class="mr-2"
                      width="16"
                      height="13"
                      alt
                    />
                    <div>
                      Unit Battery:
                      <strong :class="batteryPercentageClassName"
                        >{{ asset.BatteryPercentage }}%</strong
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="telemetry" v-else-if="hasRealTimeLocationTracking">
            <div class="row">
              <div class="col">
                <div class="speed" :class="{ danger: isSpeeding }">
                  {{ speedValue }}
                </div>
                <!-- <div class="road-speed">
                  Road Speed:
                  <span class="font-weight-bold"
                    >{{ asset.SpeedLimit }} kph</span
                  >
                </div> -->
              </div>
              <div class="col">
                <div class="date">{{ date }}</div>
                <div class="time">{{ time }}</div>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col">
                <div class="address">
                  <ul>
                    <li v-for="line in geoAddressLines" :key="line">
                      {{ line }}
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col">
                <div class="location">
                  {{ asset.Latitude + ", " + asset.Longitude }}
                </div>
                <div>
                  Battery Voltage:
                  <strong>{{ asset.BatteryVoltage }}V</strong>
                </div>
                <div>
                  Odometer Reading:
                  <strong>{{ asset.Odometer }}km</strong>
                </div>
              </div>
            </div>
          </div>
          <template v-if="!isShared">
            <!-- <svg width="20" height="20" viewBox="0 0 16 16" fill="currentColor" class="menu-toggle" v-if="!showInfoMenu" @click="showInfoMenu = !showInfoMenu">
              <path
                fill-rule="evenodd"
                d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
              />
            </svg>
            <svg width="20" height="20" viewBox="0 0 16 16" fill="currentColor" class="menu-toggle" v-if="showInfoMenu" @click="showInfoMenu = !showInfoMenu">
              <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z" />
            </svg> -->
            <!-- <SmoothReflow> -->
            <div class="info-menu">
              <div class="container">
                <div class="row">
                  <div class="col">
                    <div
                      class="menu-item"
                      :class="{ disabled: !hasTrips || isInRecovery  }"
                      @click="
                        hasTrips && !isInRecovery
                          ? showLeftDrawer('trips')
                          : handleInactiveFeature()
                      "
                    >
                      <div class="screen-icon">
                        <img
                          src="~@/assets/images/trips.svg"
                          width="22"
                          height="22"
                          alt
                        />
                      </div>
                      TRIPS
                    </div>
                  </div>
                  <div class="col">
                    <div
                      class="menu-item"
                      :class="{ disabled: !hasAlerts|| isInRecovery  }"
                      @click="
                        hasAlerts && !isInRecovery
                          ? showLeftDrawer('alerts')
                          : handleInactiveFeature()
                      "
                    >
                      <div class="screen-icon">
                        <img
                          src="~@/assets/images/alerts.svg"
                          width="22"
                          height="22"
                          alt
                        />
                      </div>
                      ALERTS
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div
                      class="menu-item"
                      :class="{ disabled: !hasLogbooks || isInRecovery  }"
                      @click="
                        hasLogbooks || isInRecovery
                          ? showModal('logbooks')
                          : handleInactiveFeature()
                      "
                    >
                      <div class="screen-icon">
                        <img
                          src="~@/assets/images/logbooks.svg"
                          width="21"
                          height="18"
                          alt
                        />
                      </div>
                      LOGBOOKS
                    </div>
                  </div>
                  <div class="col">
                    <div
                      class="menu-item"
                      :class="{ disabled: !hasMap || isInRecovery }"
                      @click="
                        hasMap && !isInRecovery
                          ? showLeftDrawer('geo-zones')
                          : handleInactiveFeature()
                      "
                    >
                      <div class="screen-icon">
                        <img
                          src="~@/assets/images/geo-zones.svg"
                          width="20"
                          height="20"
                          alt
                        />
                      </div>
                      GEO ZONES
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div
                      class="menu-item"
                      :class="{ disabled: !hasBehaviour || isInRecovery }"
                      @click="
                        hasBehaviour && !isInRecovery
                          ? showModal('behaviour')
                          : handleInactiveFeature()
                      "
                    >
                      <div class="screen-icon">
                        <img
                          src="~@/assets/images/behaviour.svg"
                          width="22"
                          height="20"
                          alt
                        />
                      </div>
                      BEHAVIOUR
                    </div>
                  </div>
                  <div class="col">
                    <div class="menu-item" @click="showModal('certificates')">
                      <div class="screen-icon">
                        <img
                          src="~@/assets/images/certificate.svg"
                          width="16"
                          height="25"
                          alt
                        />
                      </div>
                      CERTIFICATES
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="menu-item" @click="showModal('manage-vehicle')">
                      <div class="screen-icon">
                        <img
                          src="~@/assets/images/manage-vehicle.svg"
                          width="21"
                          height="21"
                          alt
                        />
                      </div>
                      MANAGE VEHICLE
                    </div>
                  </div>
                  <div class="col">
                    <div class="menu-item" @click="showModal('reminders')">
                      <div class="screen-icon">
                        <img
                          src="~@/assets/images/reminders.svg"
                          width="19"
                          height="20"
                          alt
                        />
                      </div>
                      REMINDERS
                    </div>
                  </div>
                </div>
                <div class="row">
                  <!-- <div class="col">
                      <div
                        class="menu-item"
                        :class="{ disabled: !hasShareVehicle }"
                        @click="hasShareVehicle ? showModal('share-vehicles') : handleInactiveFeature()"
                      >
                        <div class="screen-icon">
                          <img src="~@/assets/images/share-vehicle.svg" width="19" height="22" alt />
                        </div>
                        SHARE VEHICLE
                      </div>
                    </div> -->
                  <!-- <div class="col" v-if="hasArmDisarm">
                    <div
                      class="menu-item"
                      :class="{
                        armed: isArmed && !isInRecovery,
                        disabled: isInRecovery,
                      }"
                      @click="
                        isInRecovery
                          ? handleInactiveFeature()
                          : showModal('early-warning')
                      "
                    >
                      <div class="screen-icon">
                        <img
                          :src="earlyWarningIconUrl"
                          width="21"
                          height="20"
                          alt
                        />
                      </div>
                      ARM OR DISARM
                    </div>
                  </div> -->
                  <div class="col">
                    <div
                      class="menu-item"
                      :class="{
                        warning: !isHealthy && !isInRecovery,
                        disabled: isInRecovery,
                      }"
                      @click="
                        isInRecovery
                          ? handleInactiveFeature()
                          : showModal('health-status')
                      "
                    >
                      <div class="screen-icon">
                        <img
                          :src="healthStatusIconUrl"
                          width="21"
                          height="21"
                          alt
                        />
                      </div>
                      HEALTH STATUS
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="arm-disarm-panel" v-if="hasArmDisarm">
              <div class="control">
                <img src="@/assets/images/early-warning-disarm.svg" class="alert-icon" width="14" height="24" alt />
                <span class="ml-3 mr-2">Disarm</span>
                <div class="custom-control custom-switch">
                  <input type="checkbox" class="custom-control-input" id="toggleArmedStatus" v-model="asset.IsArmed" @click.prevent="toggleArmedStatus" />
                  <label class="custom-control-label" for="toggleArmedStatus" />
                </div>
                <span class="ml-2 mr-3">Arm</span>
                <img src="@/assets/images/early-warning-arm.svg" class="alert-icon" width="14" height="24" alt />
              </div>
              <div class="status">
                <span>Status: </span
                ><span class="font-weight-bold" :class="{ armed: asset.IsArmed, disarmed: !asset.IsArmed }">{{ asset.IsArmed ? "Armed" : "Disarmed" }}</span>
              </div>
            </div>
            <div class="py-1 mt-3" v-if="isUpgradeable">
              <div class="upgrade-status" v-if="asset.PendingUpgrade">
                <img
                  src="~@/assets/images/vehicle-fitment.svg"
                  width="24"
                  height="24"
                  alt
                />
                <div>Upgrade Status: <span>Awaiting Fitment</span></div>
              </div>
              <div class="text-right" v-else>
                <button class="btn btn-outline" @click="upgrade()">
                  UPGRADE PRODUCT
                </button>
              </div>
            </div>
            <!-- </SmoothReflow> -->
          </template>
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.asset-icon-component {
  position: relative;

  &.noMapFeatureViewer {
    z-index: 1;
  }

  .armed-badge {
    position: absolute;
    top: 0;
    right: 0;
  }

  .health-warning {
    background: $warning;
    position: absolute;
    top: 0;
    right: 20px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }

  .icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;
    width: 80px;
    height: 80px;
    text-align: center;
    border-radius: 50%;
    box-shadow: $drop-shadow;

    img {
      width: 100%;
      height: auto;
      border-radius: 50%;
    }

    &.marker {
      border: none;

      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        border: 2px solid $brand-color-2;
        animation: pulse 2s ease 0s infinite;
      }

      @keyframes pulse {
        0% {
          opacity: 1;
          transform: scale(1);
        }
        80% {
          opacity: 0;
          transform: scale(2);
        }
        100% {
          opacity: 0;
          transform: scale(3);
        }
      }
    }

    &.featureViewer {
      z-index: -2;

      &.active {
        z-index: 2;
      }

      &.sendToBack {
        position: relative;
      }
    }

    &.shared {
      background: $brand-color-5;
    }

    &.active {
      border: 3px solid rgba($brand-color-2, 0.8);
    }

    &.isInRecovery {
      border: 3px solid #c3281c;

      &:after {
        content: "";
        background: rgba(#c3281c, 0.5);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }

    &.assetPicker {
      &.lower {
        z-index: -2;
      }
    }

    &:hover {
      cursor: pointer;
    }

    .pin-icon,
    .share-vehicle-icon {
      stroke: $brand-color-1;
    }

    .text {
      width: 100%;
      font-weight: bold;
      font-size: 0.75rem;
      color: $brand-color-1;
      text-transform: uppercase;
      margin: 2px 0;

      &.truncate {
        width: 60px;
        line-height: inherit;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    hr {
      width: 20px;
      margin: 0;
      border-top: 1px solid $brand-color-1;
    }

    &.default {
      background: $brand-color-1;

      .text {
        color: #fff;
      }
    }
  }

  .info {
    background: #fff;
    position: absolute;
    top: 50%;
    left: 75%;
    z-index: -1;
    width: 415px;
    color: $brand-color-2;
    box-shadow: $drop-shadow;

    .header {
      display: flex;
      align-items: center;
      background: $brand-color-2;
      padding: 5px 40px 5px 30px;
      box-shadow: $drop-shadow-light;

      .title {
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 1rem;
        font-weight: bold;
        font-style: normal;
        color: #fff;
        text-transform: uppercase;
      }

      .separator {
        color: #fff;
        margin: 0 10px;
      }

      .product-name {
        color: #fff;
        text-transform: uppercase;
      }

      .notification {
        position: absolute;
        top: -10px;
        right: 35px;
      }

      .close {
        background: transparent;
        position: absolute;
        top: 7px;
        right: 9px;
        width: 16px;
        height: 16px;
        opacity: 1;

        &:before,
        &:after {
          background: #fff;
          position: absolute;
          top: 0;
          left: 8px;
          content: " ";
          height: 16px;
          width: 2px;
        }

        &:before {
          transform: rotate(45deg);
        }

        &:after {
          transform: rotate(-45deg);
        }

        &:focus {
          outline: none;
        }
      }
    }

    .content {
      padding-top: 15px;
      padding-bottom: 15px;

      .recovery {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 0.75rem;
        font-weight: 600;
        line-height: 1.3;
        color: #c3281c;
        text-align: center;
        padding: 5px 20px 20px 20px;

        strong {
          margin-top: 15px;
        }

        a {
          font-weight: bolder;
          color: #c3281c;
        }
      }

      .basic-iot {
        margin-bottom: 15px;

        .box {
          background: $brand-color-4;
          color: #4c5258;
          padding: 20px 15px 15px 15px;

          .update-blurb {
            display: flex;
            align-items: flex-start;
            font-size: 0.9375rem;
            margin-bottom: 15px;

            > img {
              margin-top: -2px;
              margin-right: 2px;
            }

            .green {
              color: #65bc36;
            }
          }

          .container {
            background: #fff;
            font-size: 0.8125rem;

            .good {
              color: #65bc36;
            }

            .warning {
              color: #ff7f00;
            }
          }
        }
      }

      .telemetry {
        margin-bottom: 15px;

        .row {
          .col {
            padding-top: 5px;
            padding-bottom: 5px;
          }

          .col:first-of-type {
            text-align: right;
            border-right: 1px solid $brand-color-2;
          }
        }

        hr {
          width: 90%;
          margin: 0 auto;
          border-color: $brand-color-2;
        }

        .speed {
          font-weight: bold;
          font-size: 1.375rem;
          color: #3aa000;

          &.danger {
            color: $red;
          }
        }

        .road-speed {
          font-size: 0.75rem;
        }

        .date {
          font-weight: bold;
          font-size: 1.125rem;
        }

        .time {
          font-size: 1.125rem;
        }

        .address {
          ul {
            font-weight: bold;
            list-style: none;
            text-align: right;
            padding: 0;
            margin: 0;
          }
        }

        .location {
          font-weight: bold;
          text-align: left;
          margin-bottom: 2px;
        }
      }

      .menu-toggle {
        display: block;
        fill: $brand-color-2;
        margin: 5px auto;

        &:hover {
          cursor: pointer;
        }
      }

      .info-menu {
        background: $brand-color-4;
        padding: 15px;

        .container {
          background: #fff;
          padding: 5px 0;
          box-shadow: $drop-shadow-light;

          .row {
            margin-left: 0;
            margin-right: 0;

            .menu-item {
              display: flex;
              align-items: center;
              font-weight: bold;
              font-style: normal;
              font-size: 0.8125rem;
              padding: 5px 0;

              &.armed {
                color: $red;
              }

              &.warning {
                color: $warning;
              }

              .screen-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 22px;
                height: 25px;
                margin-right: 5px;

                img {
                  vertical-align: bottom;
                }
              }

              &:hover {
                cursor: pointer;
              }

              &.disabled {
                opacity: 0.5;
                filter: grayscale(1);
              }
            }
          }
        }
      }

      .arm-disarm-panel {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: $brand-color-4;
        color: $brand-color-1;
        padding: 15px;
        margin-top: 10px;

        .control {
          display: flex;
          align-items: center;
          justify-content: center;
          flex: 1;
          font-size: 0.9375rem;
          font-weight: 600;
        }

        .status {
          width: 120px;
          font-size: 0.75rem;
          text-align: center;

          .armed {
            color: #338905;
          }

          .disarmed {
            color: #d50002;
          }
        }
      }

      .upgrade-status {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.8125rem;
        font-weight: bold;
        color: $brand-color-1;

        img {
          margin-right: 10px;
        }

        span {
          font-style: normal;
          font-weight: 400;
        }
      }
    }
  }
}
</style>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import {alertService, assetService, modalService} from "@/services";
import ArmDisarmConfirmationDialog from "@/components/ArmDisarmConfirmationDialog";

export default {
  name: "AssetIcon",
  components: {
    ArmDisarmConfirmationDialog,
  },
  props: [
    "isMarker",
    "isFeatureViewer",
    "isAssetPicker",
    "hideFeatureViewer",
    "registrationNumber",
    "vehicleId",
    "active",
    "sendToBack",
  ],
  data() {
    return {
      asset: {},
      openInfoTimeoutId: null,
      hovered: false,
      showInfo: false,
      // showInfoMenu: false,
    };
  },
  computed: {
    ...mapGetters("asset", ["getAsset", "getActiveVehicleId"]),
    ...mapState("map", ["selectedMarkerAssetId"]),
    ...mapState("navigation", {
      mainMenuShow: "show",
    }),
    activeVehicleId() {
      return this.getActiveVehicleId();
    },
    isNano() {
      return this.asset && this.asset.ProductName === "Nano";
    },
    isBasic() {
      return this.asset && this.asset.ProductName === "Basic";
    },
    isBasicIoT() {
      return (
        this.asset && this.asset.ProductDisplayName === "Netstar Basic IoT"
      );
    },
    isUpgradeable() {
      return this.isNano || this.isBasic;
    },
    isHealthy() {
      return this.asset && this.asset.IsHealthy;
    },
    isInRecovery() {
      return this.asset && this.asset.IsInRecovery;
    },
    isArmed() {
      return this.hasArmDisarm && this.asset.IsArmed;
    },
    isShared() {
      return this.asset && this.asset.shared ? this.asset.shared : false;
    },
    isSpeeding() {
      return this.asset.Speed > Math.abs(this.asset.SpeedLimit);
    },
    speedValue() {
      return this.asset.Speed > 0
        ? this.asset.Speed + " kph"
        : this.asset.EventDescription;
    },
    geoAddressLines() {
      return this.asset.GeoAddress ? this.asset.GeoAddress.split(",") : "";
    },
    earlyWarningIconUrl() {
      return this.isArmed
        ? require("@/assets/images/early-warning-armed.svg")
        : require("@/assets/images/early-warning-disarmed.svg");
    },
    healthStatusIconUrl() {
      return this.isHealthy
        ? require("@/assets/images/health-status-good.svg")
        : require("@/assets/images/health-status-bad.svg");
    },
    batteryPercentageClassName() {
      if (this.asset) {
        return this.asset.BatteryPercentage > 40 ? "good" : "warning";
      }
      return "";
    },
    date() {
      return this.asset.DateTime ? this.asset.DateTime.split("T")[0] : "";
    },
    time() {
      return this.asset.DateTime ? this.asset.DateTime.split("T")[1] : "";
    },
    hasAlerts() {
      return (
        this.asset && !this.isBasicIoT && assetService.hasAlertsFeature(this.asset.ProductName)
      );
    },
    hasArmDisarm() {
      return (
        this.asset && assetService.hasArmDisarmFeature(this.asset.ProductName)
      );
    },
    hasBehaviour() {
      return (
        this.asset &&
        !this.isBasicIoT &&
        assetService.hasBehaviourFeature(this.asset.ProductName)
      );
    },
    hasLogbooks() {
      return (
        this.asset &&
        !this.isBasicIoT &&
        assetService.hasTripsFeature(this.asset.ProductName)
      );
    },
    hasMap() {
      return (
        this.asset &&
        assetService.hasRealTimeLocationTracking(this.asset.ProductName)
      );
    },
    hasShareVehicle() {
      return false;
      // return this.asset && assetService.hasShareVehicleFeature(this.asset.ProductName);
    },
    hasTrips() {
      return (
          this.asset &&
          !this.isBasicIoT &&
          assetService.hasTripsFeature(this.asset.ProductName)
      );
    },
    hasRealTimeLocationTracking() {
      return (
        this.asset &&
        assetService.hasRealTimeLocationTracking(this.asset.ProductName)
      );
    },
    hasUserImage() {
      if (this.asset) {
        return (
          this.asset.RegistrationNumber &&
          this.asset.ImageUrl &&
          this.asset.ImageUrl.toLowerCase().startsWith("http")
        );
      }

      return false;
    },
  },
  created() {
    this.asset = this.getAsset(this.vehicleId);
  },
  methods: {
    ...mapActions("asset", ["setActiveAsset"]),
    ...mapActions("map", ["setMapCenter"]),
    ...mapMutations("map", ["updateSelectedMarkerAssetId"]),
    ...mapMutations("navigation", ["updateShowSheet"]),
    ...mapMutations("asset", ["updateVehicleArmedStatus"]),
    openInfo() {
      if (
        !this.$isMobile &&
        this.isMarker &&
        !this.showInfo &&
        !this.selectedMarkerAssetId
      ) {
        this.hovered = true;
        this.openInfoTimeoutId = setTimeout(() => {
          if (this.hovered) {
            this.showInfo = true;
            this.updateSelectedMarkerAssetId(this.vehicleId);

            this.setMapCenter({
              latitude: this.asset.Latitude,
              longitude: this.asset.Longitude,
            });
          }
        }, 300);
      }
    },
    clearHover() {
      this.hovered = false;
      clearTimeout(this.openInfoTimeoutId);
    },
    toggleInfo() {
      if (this.isMarker || !this.hasMap || this.isInRecovery) {
        if (this.$isMobile) {
          this.setActiveAsset(this.vehicleId);
          this.updateShowSheet(true);
        } else {
          this.showInfo = !this.showInfo;

          if (this.isFeatureViewer) {
            this.$emit("featureViewerInfoOpen", {
              open: this.showInfo,
              vehicleId: this.vehicleId,
            });
          }

          if (
              this.showInfo &&
              this.asset.Latitude &&
              this.asset.Longitude &&
              !this.isInRecovery) {
            this.setMapCenter({
              latitude: this.asset.Latitude,
              longitude: this.asset.Longitude,
            });
          }
          this.updateSelectedMarkerAssetId(
            this.showInfo ? this.vehicleId : null
          );
        }
      }
    },
    hide() {
      this.showInfo = false;
      // this.showInfoMenu = false;
      if (this.selectedMarkerAssetId == this.vehicleId) {
        this.updateSelectedMarkerAssetId(null);
      }
    },
    showModal(screenName) {
      try {
        this.$gtag.event("vehicle_" + screenName);
      } catch (err) {}
      this.setActiveAsset(this.vehicleId);
      modalService.showModal(this, screenName);
      this.hide();
    },
    showLeftDrawer(screenName) {
      try {
        this.$gtag.event("vehicle_" + screenName);
      } catch (err) {}
      this.setActiveAsset(this.vehicleId);
      modalService.showLeftDrawer(this, screenName);
      this.hide();
    },
    handleInactiveFeature() {
      this.setActiveAsset(this.vehicleId);

      if (this.isInRecovery) {
        modalService.showRecoveryInProgressDialog(this);
      } else if (this.isBasicIoT) {
        modalService.showNotSupportedNoUpgradeDialog(this);
      } else {
        modalService.showNotSupportedDialog(this);
      }
    },
    upgrade() {
      try {
        this.$gtag.event("upgrade_product");
      } catch (err) {}
      this.setActiveAsset(this.vehicleId);
      this.showInfo = false;
      modalService.showModal(this, "product-upgrade-options");
    },
    toggleArmedStatus() {
      modalService.showArmDisarmConfirmationDialog(this.asset.IsArmed ? "disarm" : "arm", () => {
        const vehicleId = this.asset.SafeAndSoundVehicleId;
        const updatedArmedValue = !this.asset.IsArmed;

        assetService
          .updateVehicleArmedStatus(vehicleId, updatedArmedValue)
          .then((result) => {
            if (result) {
              modalService.showArmDisarmRequestReceivedDialog(() => {
                setTimeout(() => {
                  this.updateVehicleArmedStatus({
                    vehicleId: vehicleId,
                    status: updatedArmedValue,
                  });
                }, 5000);
              });
            } else {
              modalService.showErrorDialog();
            }
          })
          .catch(() => {
            modalService.showErrorDialog();
          });
      });
    },
  },
  watch: {
    activeVehicleId(value) {
      if (this.isFeatureViewer) {
        const isActive = this.asset && this.asset.VehicleId === value;
        if (this.hasMap) {
          this.showInfo = isActive;
        }
      }
    },
    vehicleId() {
      this.asset = this.getAsset(this.vehicleId);
      this.showInfo = false;
      // this.showInfoMenu = false;
    },
    mainMenuShow(value) {
      if (value) {
        this.showInfo = false;
        // this.showInfoMenu = false;
      }
    },
    hideFeatureViewer() {
      this.showInfo = false;
    },
    selectedMarkerAssetId(value) {
      if (value != this.vehicleId) {
        this.hide();
      }
    },
  },
};
</script>

import Vue from "vue";
import { assetService } from "@/services";

function initialState() {
  return {
    assets: [],
    subscriber: {},
    productFeatures: {},
    activeRegistrationNumber: "",
    activeSafeAndSoundVehicleId: "",
    upgradeInProgress: false,
    upgradeProductName: null,
    upgradePartnerCode: null,
    upgradeFitmentAddress: null,
  };
}

const state = initialState();

const actions = {
  getVehicles({ commit, dispatch, getters }) {
    return new Promise((resolve, reject) => {
      const productConfigRequest = assetService.getProductConfigurationSettings();
      const subscriberRequest = assetService.getSubscriberDetails();
      const vehiclesRequest = assetService.getVehicles();

      Promise.all([productConfigRequest, subscriberRequest, vehiclesRequest])
        .then((responses) => {
          const productSettings = responses[0];
          commit("getProductSettingsSuccess", productSettings);

          const subscriber = responses[1];
          commit("getSubscriberSuccess", subscriber);

          // Vehicles endpoint returns list of units, not vehicles.
          const units = responses[2];

          // Group units by vehicle
          let unitsGroupedByVehicle = [];
          for (const unit of units) {
            if (!(unit.VehicleId in unitsGroupedByVehicle)) {
              unitsGroupedByVehicle[unit.VehicleId] = [];
            }

            // Set priority level for each unit based upon product type.
            let priority = 0;

            if (unit.ProductName == "Basic") {
              priority = 1;
            }
            if (unit.ProductDisplayName == "Netstar Basic IoT") {
              priority = 2;
            } else if (unit.ProductName == "Plus") {
              priority = 3;
            } else if (unit.ProductName == "Early Warning") {
              priority = 4;
            }

            unit.priority = priority;

            unitsGroupedByVehicle[unit.VehicleId].push(unit);
          }

          let assets = [];

          for (const vehicleId in unitsGroupedByVehicle) {
            // Sort list of units in descending order of priority.
            unitsGroupedByVehicle[vehicleId].sort((a, b) =>
              a.priority > b.priority ? -1 : 1
            );
            // Only add unit with highest priority. We only want to display one unit per vehicle,
            // also making sure the unit has all the required data.
            for (const unit of unitsGroupedByVehicle[vehicleId]) {
              if (unit.priority > 1) {
                if (unit.Latitude && unit.Longitude) {
                  assets.push(unit);
                  break;
                }
              } else {
                assets.push(unit);
                break;
              }
            }
          }

          assets.map((asset) => {
            const features = getters.getProductFeatures(asset.ProductName);
            if (
              asset.Latitude &&
              asset.Longitude &&
              !asset.IsInRecovery &&
              features.LatestVehicleMapLocation &&
              features.LatestTripMenuBar
            ) {
              asset.show = true;
            } else {
              asset.disabled = true;
            }

            if (asset.ProductName == "Nano") {
              asset.IsHealthy = true;
            }

            if (!asset.ProductDisplayName) {
              asset.ProductDisplayName = "Netstar " + asset.ProductName;
            }

            asset.shared = asset.VehicleOwnerId !== subscriber.Id;

            if (asset.shared) {
              return asset;
            }

            assetService
              .preUpgradeValidation(asset.VehicleId, asset.UnitSerialNumber)
              .then((data) => {
                asset.PendingUpgrade = data.PendingUpgrade;
              })
              .catch(() => {})
              .finally(() => {
                return asset;
              });
          });

          assets = assets.filter((asset) => !asset.shared); // Shared vehicles are not supported at this time

          assets.sort((a, b) =>
            a.shared > b.shared
              ? 1
              : a.shared === b.shared
              ? a.RegistrationNumber > b.RegistrationNumber
                ? 1
                : -1
              : -1
          );

          commit("getAllVehiclesSuccess", assets);

          resolve(assets.length > 0);
        })
        .catch((error) => {
          commit("getAllVehiclesFailure", error);
          dispatch("alert/error", error, { root: true });
          reject(error);
        });
    });
  },
  updateLocationData({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      assetService
        .getVehiclesLocationData()
        .then((data) => {
          commit("mergeLocationData", data);
          dispatch("map/panToBounds", {}, { root: true });
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  setActiveAsset({ commit, getters }, vehicleId) {
    // return new Promise((resolve, reject) => {
    commit("updateActiveSafeAndSoundVehicleId", vehicleId);
    commit(
      "updateActiveRegistrationNumber",
      getters.getVehicleRegistrationNumber(vehicleId)
    );
    // resolve();
    // });
  },
};

const getters = {
  getProductFeatures: (state) => (productName) => {
    const productNameAsProperty = productName.replace(" ", "");
    if (state.productFeatures.hasOwnProperty(productNameAsProperty)) {
      return state.productFeatures[productNameAsProperty];
    }
    return {};
  },
  getAsset: (state) => (vehicleId) => {
    return state.assets.find(
      (asset) => asset.SafeAndSoundVehicleId == vehicleId
    );
  },
  getAllAssets: (state) => () => {
    return state.assets;
  },
  getMyAssets: (state) => () => {
    return state.assets.filter((asset) => !asset.shared);
  },
  getVehiclesSharedWithMe: (state) => () => {
    return state.assets.filter((asset) => asset.shared);
  },
  getVehicleRegistrationNumber: (state) => (vehicleId) => {
    return state.assets.find(
      (asset) => asset.SafeAndSoundVehicleId == vehicleId
    ).RegistrationNumber;
  },
  getActiveVehicleRegistrationNumber: (state) => () => {
    if (state.activeSafeAndSoundVehicleId) {
      return state.assets.find(
        (asset) =>
          asset.SafeAndSoundVehicleId == state.activeSafeAndSoundVehicleId
      ).RegistrationNumber;
    }
    return "";
  },
  getActiveVehicleOwnerId: (state) => () => {
    if (state.activeSafeAndSoundVehicleId) {
      return state.assets.find(
        (asset) =>
          asset.SafeAndSoundVehicleId == state.activeSafeAndSoundVehicleId
      ).VehicleOwnerId;
    }
    return state.assets[0].VehicleOwnerId;
  },
  getActiveVehicleId: (state) => () => {
    if (state.activeSafeAndSoundVehicleId) {
      return state.assets.find(
        (asset) =>
          asset.SafeAndSoundVehicleId == state.activeSafeAndSoundVehicleId
      ).VehicleId;
    }
    return "";
  },
  getActiveVehicleNickname: (state) => () => {
    if (state.activeSafeAndSoundVehicleId) {
      return state.assets.find(
        (asset) =>
          asset.SafeAndSoundVehicleId == state.activeSafeAndSoundVehicleId
      ).Nickname;
    }
    return "";
  },
  getActiveVehicleDisplayName: (state, getters) => () => {
    const nickname = getters.getActiveVehicleNickname();
    if (nickname) {
      return nickname;
    }
    return getters.getActiveVehicleRegistrationNumber();
  },
  getActiveVehicleProductName: (state) => () => {
    if (state.activeSafeAndSoundVehicleId) {
      let asset = state.assets.find(
        (asset) =>
          asset.SafeAndSoundVehicleId == state.activeSafeAndSoundVehicleId
      );

      if (asset.ProductDisplayName == "Netstar Basic IoT") {
        return asset.ProductDisplayName;
      }

      return asset.ProductName;
    }
    return "";
  },
  // getSafeAndSoundVehicleId: (state) => (registrationNumber) => {
  //   return state.assets.find(
  //     (asset) => asset.RegistrationNumber === registrationNumber
  //   ).SafeAndSoundVehicleId;
  // },
  getActiveSafeAndSoundVehicleId: (state) => () => {
    return state.activeSafeAndSoundVehicleId;
  },
  getActiveVehicleIsEarlyWarning: (state) => () => {
    if (state.activeSafeAndSoundVehicleId) {
      return (
        state.assets.find(
          (asset) =>
            asset.SafeAndSoundVehicleId == state.activeSafeAndSoundVehicleId
        ).ProductName === "Early Warning"
      );
    }
    return false;
  },
  getActiveVehicleIsArmed: (state) => () => {
    if (state.activeSafeAndSoundVehicleId) {
      return state.assets.find(
        (asset) =>
          asset.SafeAndSoundVehicleId == state.activeSafeAndSoundVehicleId
      ).IsArmed;
    }
    return false;
  },
  getActiveVehicleIsHealthy: (state) => () => {
    if (state.activeSafeAndSoundVehicleId) {
      return state.assets.find(
        (asset) =>
          asset.SafeAndSoundVehicleId == state.activeSafeAndSoundVehicleId
      ).IsHealthy;
    }
    return false;
  },
  getActiveVehicleUnitSerialNumber: (state) => () => {
    if (state.activeSafeAndSoundVehicleId) {
      return state.assets.find(
        (asset) =>
          asset.SafeAndSoundVehicleId == state.activeSafeAndSoundVehicleId
      ).UnitSerialNumber;
    }
    return false;
  },
  getActiveVehicleImageUrl: (state) => () => {
    if (state.activeSafeAndSoundVehicleId) {
      return state.assets.find(
        (asset) =>
          asset.SafeAndSoundVehicleId == state.activeSafeAndSoundVehicleId
      ).ImageUrl;
    }
    return false;
  },
  getSubscriberEmailAddress: (state) => () => {
    if (state.subscriber.Email) {
      return state.subscriber.Email;
    }
    return "";
  },
  getSubscriberIdNumber: (state) => () => {
    if (state.subscriber.IdNumber) {
      return state.subscriber.IdNumber;
    }
    return "";
  },
  getUpgradeInProgress: (state) => () => {
    return state.upgradeInProgress;
  },
  getUpgradeProductName: (state) => () => {
    return state.upgradeProductName;
  },
  getUpgradePartnerCode: (state) => () => {
    return state.upgradePartnerCode;
  },
  getUpgradeFitmentAddress: (state) => () => {
    return state.upgradeFitmentAddress;
  },
};

const mutations = {
  reset(state) {
    const s = initialState();
    Object.keys(s).forEach((key) => {
      state[key] = s[key];
    });
  },
  getAllVehiclesSuccess(state, assets) {
    if (state.assets.length) {
      assets.forEach((asset) => {
        state.assets.forEach((stateAsset, index) => {
          if (asset.VehicleId == stateAsset.VehicleId) {
            stateAsset = Object.assign(stateAsset, asset);
          }
          Vue.set(state.assets, index, stateAsset);
        });
      });
    } else {
      state.assets = assets;
    }
  },
  getAllVehiclesFailure(state) {
    state.assets = [];
  },
  getProductSettingsSuccess(state, productSettings) {
    productSettings.forEach((product) => {
      state.productFeatures[product.Name.replace(" ", "")] = product;
    });
  },
  getSubscriberSuccess(state, subscriber) {
    state.subscriber = subscriber;
  },
  setMapActive(state, assetId) {
    state.assets.map((asset) => {
      asset.show = assetId
        ? asset.SafeAndSoundVehicleId == assetId && !asset.disabled
        : !asset.disabled;
      return asset;
    });
  },
  // setSelected(state, assetId) {
  //   state.assets.map((asset) => {
  //     asset.selected = asset.SafeAndSoundVehicleId === assetId;
  //     return asset;
  //   });
  // },
  updateActiveRegistrationNumber(state, registrationNumber) {
    state.activeRegistrationNumber = registrationNumber;
  },
  updateActiveSafeAndSoundVehicleId(state, vehicleId) {
    state.activeSafeAndSoundVehicleId = parseInt(vehicleId);
  },
  updateVehicleArmedStatus(state, { vehicleId, status }) {
    state.assets.map((asset) => {
      if (asset.SafeAndSoundVehicleId == vehicleId) {
        asset.IsArmed = status;
      }
      return asset;
    });
  },
  updateVehicleImageUrl(state, { vehicleId, value }) {
    state.assets.map((asset) => {
      if (asset.SafeAndSoundVehicleId == vehicleId) {
        asset.ImageUrl = value;
      }
      return asset;
    });
  },
  updateVehicleNickname(state, { vehicleId, value }) {
    state.assets.map((asset) => {
      if (asset.SafeAndSoundVehicleId == vehicleId) {
        asset.Nickname = value;
      }
      return asset;
    });
  },
  mergeLocationData(state, data) {
    state.assets.forEach((asset) => {
      const vehicleLocationData = data.find(
        (item) => asset.SafeAndSoundVehicleId == item.SafeAndSoundVehicleId
      );
      if (vehicleLocationData) {
        Object.assign(asset, vehicleLocationData);
      }
    });
  },
  setUpgradeStarted(state, { productName, partnerCode }) {
    state.upgradeInProgress = true;
    state.upgradeProductName = productName;
    state.upgradePartnerCode = partnerCode;
  },
  setUpgradeFitmentAddress(state, fitmentAddress) {
    state.upgradeFitmentAddress = fitmentAddress;
  },
  resetUpgradeState(state) {
    state.upgradeInProgress = false;
    state.upgradeProductName = null;
    state.upgradePartnerCode = null;
    state.upgradeFitmentAddress = null;
  },
};

export const asset = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
